import http from '@/utils/http'

export function login(data){
    return http.post('/sessions',data)
}
export function logout(params){
    return http.delete('/sessions',{params})
}
export function refresh(params){
    return http.get('/access_token',{params})
}