import axios from 'axios'
import { toSnakeCase, toCamelCase } from './data-format'
import { getToken,removeToken,setToken,getUserInfo,removeAll } from '@/utils/auth'
import router from '@/router'
import { Message } from 'element-ui';
import { refresh } from '@/api/security'
const http = axios.create({
  // baseURL: process.env.NODE_ENV  === "development" ? '/api' : process.env.VUE_APP_BASE_API,
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: '/api',
  // baseURL: 'https://plastic-free.dev-a.kanhan.com/api/portal/v1',

  timeout: 600000
})

http.interceptors.request.use(
  config => {
    const token = getToken()
    config.headers.common['Authorization'] = `Bearer ${token}`
    config.headers.common['accept-language'] = `en`
    if (!config.unformat) {
      config.params = toSnakeCase(config.params)
      config.data = toSnakeCase(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    // console.log(response)
    if (response.headers['content-type'] === 'application/json') {
      
      response.data = toCamelCase(response.data)
    }
    return response
  },
  error => {
    if (error.response.status === 401) {
      let d={
        refreshToken: getUserInfo('userInfo').refreshToken
      }
      refresh(d).then((v)=>{
        setToken(v.data.accessToken)
      }).catch(()=>{
        removeToken()
        removeAll()
        router.push({path:'/login'})
      }).finally(()=>{
        location.reload();
      })
    }  else if (error.response.status === 500) {      
      Message.error(error.response.data.message);
    }   else {
      Message.error(error.response.data.error_message);
    }
    return Promise.reject(error)
  }
)

export default http
